<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <v-row wrap>
            <v-col cols=12 md=4>
                <v-btn text class="primary--text plain" @click="toggleFilters()" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon right class='mr-1'>mdi-filter</v-icon>
                    <span v-text="$t($store.state.showFilters ? 'map.filters.hide' : 'map.filters.show')"></span>
                </v-btn>
                <div v-if="$store.state.showFilters || !$vuetify.breakpoint.smAndDown">
                <v-btn text class="primary--text plain my-5" @click="resetFilters()">
                    <v-icon right class='mr-1'>remove</v-icon>
                    <span>{{ $t("map.filters.clear") }}</span>
                </v-btn>
                <v-card class="py-5 px-5" flat style="border-radius: 20px">
                    <div class="subtitle-1 grey--text text--darken-1 mb-3">{{ $t("map.region.text") }}</div>
                    <v-row class="mx-2">
                        <v-col cols=12 class="pa-0">
                            <InputField
                                class="mt-3"
                                :label="$t('map.region.label')"
                                icon="near_me"
                                v-model="$store.state.filteredRegion"
                                inputType="regionAutocomplete"
                                :regionAll="true"
                                :regionMy="$store.state.region ? true : false"
                                :inputValue="$store.state.filteredRegion"
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
                    <v-row class="ma-0 mb-5">
                        <v-col cols=12>
                        <InputField
                            :label="$t('map.tag.label')"
                            icon="local_offer"
                            v-model="$store.state.filteredTags"
                            inputType="selectTags"
                            :inputValue="[...$store.state.filteredTags]"
                            :tagsColumn="true"
                        />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-5"></v-divider>
                    <div class="subtitle-1 grey--text text--darken-1">{{ $t("map.name.text") }}</div>
                    <v-row class="mx-2">
                        <v-col cols=12 class="pa-0 mr-2">
                            <InputField
                                class="mt-3"
                                :label="$t('map.name.label')"
                                icon="search"
                                v-model="$store.state.filteredText"
                                inputType="text"
                                :inputValue="$store.state.filteredText"
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mb-5"></v-divider>
                    <v-row class="ma-0 mb-5">
                        <v-checkbox class="ma-0 mb-3" hide-details v-model="$store.state.showFilteredDate" :label="$t('map.date.textCheckbox')"></v-checkbox>
                        <v-col v-if="$store.state.showFilteredDate" cols=12 class="pa-0">
                            <InputField
                                :label="$t('map.date.label')"
                                icon="date_range"
                                v-model="$store.state.filteredDateRange"
                                :inputValue="$store.state.filteredDateRange"
                                inputType="dateRange"
                            />
                            <div class="subtitle-1 grey--text text--darken-1">{{ $t('map.date.textFilter') }}</div>
                            <WeekdaysDisplay :inputDisplay="true" v-model="$store.state.filteredDays" :weekdays="parseFilteredDays()" outputForm="filter" />
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 mb-5">
                        <v-checkbox class="ma-0 mb-3" hide-details v-model="$store.state.showLongtime" :label="$t('map.longTerm.label')"></v-checkbox>
                    </v-row>
                </v-card>
                </div>
            </v-col>
            <v-col cols=12 md=8 class="mt-10">
              <v-card class="rounded-lg">
                <Map 
                  :events="displayedEvents" 
                  :zoom="7.5" 
                />
              </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import utilitiesMixin from '@/mixins/utilitiesMixin.js'
import InputField from '@/components/partials/InputField'
import PopupDialog from '@/components/partials/PopupDialog'
import WeekdaysDisplay from '@/components/partials/WeekdaysDisplay'
import Map from '@/components/MapLeaflet'
import axios from 'axios'
    export default {
        name: "WebAppHome",
        components: {Map, InputField, PopupDialog, WeekdaysDisplay},
        mixins: [utilitiesMixin],
        data() {
            return {
                dialog: false,
                events: [],
                linkedEventsIds: [],
                publicMode: false,
                requestedEventsIds: [],
            }
        },
        methods: {
            getEventData() {
                axios.post('/get_offers', {onlyActive: 1})
                .then(response => {
                    this.getEventContent(response.data.offers)
                })
                .catch(() => {
                    this.dialog = true
                })
            },
            getEventContent(ids) {
                let newEvents = []
                axios.post('/get_offers_info', {ID_offers: ids})
                .then(response => {
                    response.data.info.forEach(element => {
                        let newEvent = {
                            ...element,
                            tags: (element.tags || []).map(tag => {
                              if(!this.$store.state.tags[tag]){
                                const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                                const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                                return key;
                              } else {
                                return tag;
                              }
                            }),
                            isLinked: this.linkedEventsIds.includes(element.ID_offer),
                            isRequested: this.requestedEventsIds.includes(element.ID_offer),
                        }
                        newEvents.push(newEvent)
                    });
                })
                .catch(() => {
                    this.dialog = true
                })
                this.events = newEvents
            },
            async getlinkedEventsIds() {
                let newlinkedEventsIds = []
                let newrequestedEventsIds = []
                await axios.get('/auth/get_linked_offers')
                .then(response => {
                    var links = response.data.links
                    for (let i=0;i<links.length;i++) {
                        var link = links[i]
                        if (link.isActive) {
                            newlinkedEventsIds.push(link.ID_offer)
                        }
                    }
                    var requests = response.data.requests
                    for (let i=0;i<requests.length;i++) {
                        newrequestedEventsIds.push(requests[i].ID_offer)
                    }
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
                this.linkedEventsIds = newlinkedEventsIds
                this.requestedEventsIds = newrequestedEventsIds
            },
            containsTag (tags, filteredTags) {
                if (tags) {
                    for (let tag of tags) {
                        if (filteredTags.includes(tag)) {
                            return true
                        }
                    }
                }
                return false
            },
            resetFilters () {
                this.$store.state.filteredDays = ['1','2','3','4','5','6','0']
                this.$store.state.filteredDateRange = []
                this.$store.state.showFilteredDate = false
                this.$store.state.filteredText = ""
                this.$store.state.filteredRegion = 'data.allRegions'
                this.$store.state.filteredTags = []
            },
            toggleFilters() {
                this.$store.state.showFilters = !this.$store.state.showFilters
            },
        },
        computed: {
            displayedEvents: function () {
                return this.events.filter(event => {
                    return ((this.$store.state.showLongtime && event.type == 4) ||
                    (new Date(`${event.endDate || event.nextEventDate}T${event.endTime || "23:59"}:00`) >= new Date &&
                    (event.type == 2 || event.type == 3 || event.date != event.endDate || new Date(`${event.nextEventDate}T${event.nextEventTime}:00`) > new Date) &&
                    (!this.$store.state.showFilteredDate ||
                    (this.isOneOfDaysInRange(this.$store.state.filteredDays, [event.nextEventDate, event.endDate || event.nextEventDate]) &&
                    (!this.$store.state.filteredDateRange.length ||
                    this.doDateRangesOverlap([event.nextEventDate, event.endDate || event.nextEventDate], this.$store.state.filteredDateRange)))))) &&
                    (!this.$store.state.filteredText ||
                    event.desc.toLowerCase().includes(this.$store.state.filteredText.toLowerCase()) ||
                    event.place.toLowerCase().includes(this.$store.state.filteredText.toLowerCase()) ||
                    event.title.toLowerCase().includes(this.$store.state.filteredText.toLowerCase())) &&
                    (!this.$store.state.filteredRegion || this.$store.state.filteredRegion == "data.allRegions" ||
                    this.filteredRegionCode == event.region) &&
                    (this.$store.state.filteredTags.length == 0 ||
                    this.containsTag(event.tags, this.$store.state.filteredTags))
                })
            },
            filteredRegionCode: function () {
                let region = this.$store.state.filteredRegion
                let code = 101
                if (region == 'data.myRegion') {
                    code = this.$store.state.region
                } else if (region.length && region != 'data.allRegions') {
                    const r = this.findRegionBy('displayValue', region);
                    code = r && r.region && r.region.code;
                }
                return code
            }
        },
        async created () {
            if (!this.$store.getters.loggedIn) {
                this.publicMode = true
            }
            if (!this.publicMode) {
                await this.getlinkedEventsIds()
            }
            this.getEventData()
        }
    }
</script>

<style lang="scss" scoped>

</style>
